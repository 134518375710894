@use "src/mixins" as *;
@use "src/variables" as *;

.block-6 {
  min-height: 340px;
  background: radial-gradient(62.74% 42.07% at 50% 83.85%, #F7D523 0%, #F7BC23 100%);
  position: relative;
  font-family: Manrope;

  h2 {
    margin: 5px 0;
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #1D1D1B;

    @include media($mediapoint-small, $mediapoint-medium) {}

    @include media($mediapoint-very-small, $mediapoint-small) {
      justify-content: center;
      font-size: 20px;
      margin-bottom: 15px;
      padding-top: 30px;
    }
  }

  &-main-container {
    padding: 35px 0 10px 0;
    margin: 0 0px 0 0px;

    @include media($mediapoint-medium, $mediapoint-large) {
      margin: 0 0px 0 45px !important;
    }

    @include media($mediapoint-small, $mediapoint-medium) {
      margin: 0 0px 0 45px !important;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      padding: 25px;
      margin: 0 0px 0 0px;

      &:after {
        content: '';
        height: 30px;
        width: 100%;
        position: absolute;
        bottom: -2px;
        left: 0;
        border-top-left-radius: 12px;
        z-index: 100;
        border-top-right-radius: 12px;
      }
    }
  }

  &-description {
    max-width: 80%;
    font-size: 15px;

    p {
      line-height: 24px;
      margin: 5px 0px;
    }

    span {
      font-weight: bold;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      max-width: 100%;
      text-align: left;
    }

  }

}