@use "src/mixins" as *;
@use "src/variables" as *;

.block-4 {
  min-height: 360px;
  background: radial-gradient(62.74% 42.07% at 50% 83.85%, #F7D523 0%, #F7BC23 100%);
  position: relative;

  @include media($mediapoint-small, $mediapoint-medium) {
    .container-page {
      text-align: center;
    }
  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    .container-page {
      text-align: center;
    }
  }

  h2 {
    margin: 5px 0;
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #1D1D1B;

    @include media($mediapoint-small, $mediapoint-medium) {}

    @include media($mediapoint-very-small, $mediapoint-small) {
      justify-content: center;
      font-size: 20px;
      margin-bottom: 15px;
      padding-top: 30px;
    }
  }

  .abs-posotion-img {
    position: absolute;
    bottom: -49px;
    right: 0;
    z-index: 0;

    @include media($mediapoint-medium, $mediapoint-large) {
      width: 450px;
    }

    @include media($mediapoint-small, $mediapoint-medium) {
      max-width: 400px;
      width: 100%;
      bottom: -58px;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      position: relative;
      max-width: 500px;
      width: 100%;
      bottom: 15px;
      transform: scale(-1, 1);
    }
  }

  &-main-container {
    padding: 35px 0 10px 0;
    margin: 0 0px 0 0px;

    @include media($mediapoint-medium, $mediapoint-large) {
      margin: 0 0px 0 45px !important;
    }

    @include media($mediapoint-small, $mediapoint-medium) {
      margin: 0 0px 0 45px !important;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      padding: 25px;
      margin: 0 0px 0 0px;

      &:after {
        content: '';
        height: 30px;
        width: 100%;
        background-color: #ffffff;
        position: absolute;
        bottom: -2px;
        left: 0;
        border-top-left-radius: 12px;
        z-index: 100;
        border-top-right-radius: 12px;
      }
    }
  }

  .block-4-description {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 20px;
    z-index: 1;

    @include media($mediapoint-small, $mediapoint-medium) {}

    @include media($mediapoint-very-small, $mediapoint-small) {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}