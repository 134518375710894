@use "../../../../../mixins" as *;
@use "../../../../../variables" as *;

.logo-payment {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 20px;
  width: 47%;
  text-align: center;
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  margin-top: 25px;

  &.full-width {
    width: 100%;
  }

  &.pointer {
    cursor: pointer;
  }

  @include media($mediapoint-small, $mediapoint-medium-large) {
    width: 100%;
    height: 56px;
    margin: 0px 0px 12px 0px;
  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    width: 100% !important;
    box-sizing: border-box;
    height: 56px;
    margin: 5px 0 !important;
    max-width: 100%;

    padding: 10px !important;
  }

  img {
    margin-right: 8px;
  }

  p {
    font-weight: 700;
    font-size: 12px !important;
    line-height: 1.25em;
    margin: 0 !important;
    text-align: center;

    color: #1D1D1B;
  }

}
